export const navArray = [
    {
        text: "Home",
        link: ""
    },
    {
        text: "Tall Tales",
        link: "tall-tales"
    },
    {
        text: "Talking Training",
        link: "/",
        hasSubMenu: true,
        submenu: [
            {
                text: "Tailor-Made",
                link: "training/tailor-made-training"
            },
            {
                text: "Tutorials",
                link: "training/tutorials-coaching-mentoring"
            },
            {
                text: "Toolbox",
                link: "training/toolbox"
            },
        ]
    },
    {
        text: "On Tour",
        link: "on-tour"
    },
    {
        text: "Table Talk",
        link: "table-talk"
    },
    {
        text: "Question Time",
        link: "question-time"
    },
    {
        text: "Get In Touch",
        link: "contact-us"
    },

];