import React, { useState } from 'react'
import { Link } from "gatsby";
import { navArray } from '../data/nav-data' 
import ContactIcons from './ContactIcons'



const Navigation = ({mobileMenu}) => {

    const [submenuOpen, setSubMenuOpen] = useState(false)


    // //The name is a bit excessive
    const handleSubMenuParentClick = () => {

        mobileMenu && setSubMenuOpen(!submenuOpen)

    }

    return ( 

        <nav className={submenuOpen ? 'sub-menu-open' : ''}> 
            <ul>
                {navArray.map((e,i) =>
                    <React.Fragment key={i}>
                        {e.hasSubMenu ? 
                            <li className="has-submenu" >
                        
                                <button onClick={handleSubMenuParentClick}>
                                    {e.text}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6.175" viewBox="0 0 10 6.175">
                                        <path id="Icon_material-keyboard-arrow-down" data-name="Icon material-keyboard-arrow-down" d="M10.175,11.76,14,15.577l3.825-3.817L19,12.935l-5,5-5-5Z" transform="translate(-9 -11.76)" fill="#1B3F95"/>
                                    </svg>
                                </button>
                                <ul className="sub-menu">
                                    {e.submenu.map((e,i) => 
                                        <li  key={`i-${i}`}>
                                            <Link to={`/${e.link}`}>
                                                <span>{e.text}</span>
                                            </Link>
                                        </li>
                                    )}

                                </ul>
                            </li>
                            :
                            <li>
                                <Link 
                                    to={`/${e.link}`}
                                    activeClassName="active">
                                        <span>{e.text}</span>
                                </Link>
                            </li>
                        }
                    </React.Fragment>

                )}
            </ul>

            <ContactIcons />              
        </nav>
       
    )

}

export default Navigation