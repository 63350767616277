import React from 'react'
import { Link } from 'gatsby'

const ContactIcons = () => {

    return (

        <div className="ContactIcons">
            <a href="tel:+64272457233" className="ContactIcons-holder">
                <div className="ContactIcons-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                        <path id="Icon_material-local-phone" data-name="Icon material-local-phone" d="M8.12,12.29a15.149,15.149,0,0,0,6.59,6.59l2.2-2.2a.994.994,0,0,1,1.02-.24,11.407,11.407,0,0,0,3.57.57,1,1,0,0,1,1,1V21.5a1,1,0,0,1-1,1,17,17,0,0,1-17-17,1,1,0,0,1,1-1H9a1,1,0,0,1,1,1,11.36,11.36,0,0,0,.57,3.57,1,1,0,0,1-.25,1.02l-2.2,2.2Z" transform="translate(-4.5 -4.5)"/>
                    </svg>

                    </div>
                <div className="ContactIcons-border">
                    <div className="ContactIcons-text">
                        <span>+64 27 245 7233</span>
                    </div>
                </div>
            </a>
            <a href="mailto:info@turningtables.co.nz"  className="ContactIcons-holder">
                <div className="ContactIcons-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
                                <path id="Icon_material-email" data-name="Icon material-email" d="M21,6H5A2,2,0,0,0,3.01,8L3,20a2.006,2.006,0,0,0,2,2H21a2.006,2.006,0,0,0,2-2V8A2.006,2.006,0,0,0,21,6Zm0,4-8,5L5,10V8l8,5,8-5Z" transform="translate(-3 -6)"/>
                            </svg>
                    </div>
                <div className="ContactIcons-border">
                    <div className="ContactIcons-text">
                        <span>info@turningtables.co.nz</span>
                    </div>
                </div>
            </a>
            <Link to="/contact-us" className="ContactIcons-holder">
                <div className="ContactIcons-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14.7" height="21" viewBox="0 0 14.7 21">
                    <path id="Icon_material-location-on" data-name="Icon material-location-on" d="M14.85,3A7.345,7.345,0,0,0,7.5,10.35C7.5,15.863,14.85,24,14.85,24s7.35-8.137,7.35-13.65A7.345,7.345,0,0,0,14.85,3Zm0,9.975a2.625,2.625,0,1,1,2.625-2.625A2.626,2.626,0,0,1,14.85,12.975Z" transform="translate(-7.5 -3)"/>
                    </svg>

                </div>
                <div className="ContactIcons-border">
                    <div className="ContactIcons-text">
                        <span>6 Ward Place, Richmond Heights, Taupo</span>
                    </div>
                </div>
            </Link>
        </div>
       
    )

}

export default ContactIcons