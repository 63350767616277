import { Link } from "gatsby";
import {useIntersector} from './customhooks'
import PropTypes from "prop-types";
import React, {useState, useRef} from "react";
import { useMediaQuery } from 'react-responsive';
//not sure if this is the way to go
import Navigation from './navigation'


const Header = () => {

    const [isIntersecting, setIsIntersecting] = useState(true);
    const eInterTarget = useRef();
   
    const mobileMenu = useMediaQuery({ query: '(max-width: 66em)' })
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useIntersector({
        eInterTarget, 
        rootMargin: "50px",
        onIntersect: ([{isIntersecting}]) => setIsIntersecting(isIntersecting)
    });
   
    return (
        <>
       
        <header className={`${!isIntersecting ? "scroll" : 'no-scroll'} ${mobileMenuOpen ? "menu-open" : ""}`}>
        
            
            <div className="inner">
                <Link className="logo" to="/"></Link>

            {mobileMenu && (
                <>
               
                <button 
                    className="menu-button" 
                    onClick={() => setMobileMenuOpen(!mobileMenuOpen)}><span></span></button>

                </>
            )}
            <Navigation mobileMenu={mobileMenu} />

            </div>
        </header>

        {mobileMenu && (<button className={`mobile-exit ${mobileMenuOpen ? "menu-open" : ""}`} onClick={() => setMobileMenuOpen(false)}></button>)}

        <div className="scroll-intersect" ref={eInterTarget}></div>
        </>
    )

}

Header.propTypes = {
    siteTitle: PropTypes.string,
}
  
Header.defaultProps = {
    siteTitle: ``,
}


export default Header;