import React from 'react';
import { Link } from 'gatsby';
import { navArray } from '../data/nav-data'

const Footer = () => {

    const menuArray = () => { //Hacky way to remove home from the footer

        const navCopy = [...navArray]
        navCopy.splice(0,1)
        return navCopy
    }

    const FooterMenu = () => (
        <nav> 
            <ul>
                {menuArray().map((e,i) =>
                    <React.Fragment key={i}>  
                        {e.hasSubMenu ? 
                            <li className="has-submenu" key={`fmi-${i}`}>
                        
                                <span>
                                    {e.text}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6.175" viewBox="0 0 10 6.175">
                                        <path id="Icon_material-keyboard-arrow-down" data-name="Icon material-keyboard-arrow-down" d="M10.175,11.76,14,15.577l3.825-3.817L19,12.935l-5,5-5-5Z" transform="translate(-9 -11.76)" fill="#1B3F95"/>
                                    </svg>
                                </span>
                                <ul className="sub-menu">
                                    {e.submenu.map((e,i) => 
                                        <li key={`i-${i}`}>
                                            <Link to={`/${e.link}`}>
                                                <span>{e.text}</span>
                                            </Link>
                                        </li>
                                    )}

                                </ul>
                            </li>
                            :
                            <li key={`fmi-${i}`}>
                                <Link 
                                    to={`/${e.link}`}
                                    activeClassName="active">
                                        <span>{e.text}</span>
                                </Link>
                            </li>
                        }
                    </React.Fragment>

                )}
            </ul>

    
        </nav>
    )
  
    return (

        <footer>
            <div className="outer-left"></div>
            <div className="inner">
                <div className="inner-left">

                    <div className="contact-details">
                        <h3>Get In Touch</h3>

                        <div>
                            <h4>Free Consultation</h4>
                            <p>+64 27 245 7233</p>
                        </div>
                        
                        <div>
                            <h4>Address</h4>
                            <address>
                                <span>6 Ward Place</span>
                                <span>Richmond Heights</span>
                                <span>Taupo 3330, New Zealand</span>
                            </address>
                        </div>

                        <div>
                            <h4>Email</h4>
                            <p>info@turningtables.co.nz</p>
                        </div>

                            
                    </div>   
                    
                        <p className="copyright">&copy; Turning Tables {new Date().getFullYear()}</p>
                    </div>


                <div className="inner-right">
                    <div className="turning-tables">
                        <div className="logo"></div>
                        <p>Over 30 years experience in the hospitality industry.</p>
                        <FooterMenu />
                    
                    </div>

                </div>
                
 
                
                
              
                
            </div>
            <div className="outer-right"></div>

           
        </footer>
       
    )

}

export default Footer